"use client";

import {
  useAccessReady,
  useClientDate,
  useClientInstallId,
  useClientVersion,
  useLicenseClientAccessVersion,
  useUser,
} from "@easybiz/component-shared";
import { useEffect } from "react";
import useServerActionRegisterDevice from "./useServerActionRegisterDevice";

// Init device register (Grant permissions)
function DeviceRegister({ getDeviceInfo, signInWithCustomToken, getAuth }) {
  const {
    accessVersion,
    authTime,
    uid,
    realmId,
    clientVersion: userClientVersion,
    subscriptionPeriodEnd,
  } = useUser() || {};
  const installId = useClientInstallId();
  const clientVersion = useClientVersion();
  const latestAccessVersion = useLicenseClientAccessVersion();
  const accessReady = useAccessReady();
  const clientDate = useClientDate();
  const { onSubmit } = useServerActionRegisterDevice(signInWithCustomToken, getAuth, getDeviceInfo);
  const pendingRegister =
    !accessReady ||
    latestAccessVersion > accessVersion ||
    clientVersion !== userClientVersion ||
    !subscriptionPeriodEnd ||
    subscriptionPeriodEnd < authTime;

  useEffect(() => {
    if (
      installId &&
      uid &&
      realmId &&
      pendingRegister // Submit initial device registration or permission updated after auth
    ) {
      if (typeof window !== "undefined" && window.location?.search) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
          // Waiting for token sign in
          return;
        }
      }
      console.log("DeviceRegister", { uid, realmId, installId, pendingRegister, clientDate });
      onSubmit();
    }
  }, [uid, realmId, installId, pendingRegister, clientDate]);

  return null;
}

export default DeviceRegister;
