"use client";

import { useAccessAction, useClientType, useUserBusinessCode, useUserUID } from "@easybiz/component-shared";
import {
  ACTION_CANCEL_ORDER,
  ACTION_CONFIRM_ORDER,
  ACTION_CREATE_JOB,
  ACTION_MAKE_PAYMENT,
  ACTION_SALES_RETURN,
  PERMISSION_LEVEL_BLOCKED
} from "@easybiz/lib-shared-auth";
import { CLIENT_POS, isDayLimitedNew } from "@easybiz/utils";
import { useMemo } from "react";

function useOrderPermissions(order, isDayClosed) {
  const uid = useUserUID();
  const client = useClientType();
  const businessCode = useUserBusinessCode();
  const [canEdit, canPayment, canCancel, canJobSchedule, salesReturn] = useAccessAction(
    ACTION_CONFIRM_ORDER,
    ACTION_MAKE_PAYMENT,
    ACTION_CANCEL_ORDER,
    ACTION_CREATE_JOB,
    ACTION_SALES_RETURN
  );
  const disableSalesReturn = salesReturn?.level === PERMISSION_LEVEL_BLOCKED;

  return useMemo(() => {
    if (!order || order.get("voided")) return {};

    const isPOS = client === CLIENT_POS;
    const ownOrder = businessCode === order.get("business.id") || businessCode === order.get("supplier.id");
    const collectionPlace = businessCode === order.get("collectionPlace");
    const supplier = (businessCode || uid) === order.get("supplier.id");
    const completed = order.get("completed");

    return {
      canEdit: completed ? false : (order.get("pricingTBD") || !isDayLimitedNew(order.get("pricingConfirmDate.date") || order.get("date"), canEdit)) ,
      canPayment: isPOS ? !isDayClosed && (ownOrder || collectionPlace) : canPayment,
      canCancelPayment: isPOS ? !isDayClosed && ownOrder : false,
      canRefund: isPOS ? false : false,
      canRefundOverpaid: isPOS ? !isDayClosed : false,
      canDischarge: isPOS ? !isDayClosed && (ownOrder || collectionPlace) : true,
      canNote: isPOS ? ownOrder || collectionPlace || supplier : true,
      canSalesReturn: isPOS ? !isDayClosed && !disableSalesReturn && (ownOrder || supplier) : true,
      canCancel: isPOS ? !isDayClosed && ownOrder && !isDayLimitedNew(order.get("date"), canCancel) : canCancel,
      canRedo: isPOS ? !isDayClosed && (ownOrder || supplier) : true,
      canJobSchedule: !isPOS,
    };
  }, [
    order,
    uid,
    businessCode,
    client,
    canEdit,
    canPayment,
    canCancel,
    disableSalesReturn,
    canJobSchedule,
    isDayClosed,
  ]);
}

export default useOrderPermissions;
