export const PAGE_HOME = "home";
export const PAGE_LOGIN = "login";
export const PAGE_CREATE_ORDER = "create-order";
export const PAGE_NEW_ORDER_PREVIEW = "new-order-preview";
export const PAGE_MY_JOBS = "my-jobs";
export const PAGE_LIVE_CHAT = "live-chat";
export const PAGE_NOTIFICATIONS = "notifications";
export const PAGE_CUSTOM = "custom";
export const PAGE_MY_ACCOUNT = "my-account";
export const PAGE_NO_ACCESS = "no-access";
export const PAGE_NOT_FOUND = "not-found";
export const PAGE_SYSTEM_MENU = "system-menu";
export const PAGE_PROCEDURE = "procedure";
export const PAGE_PROCEDURE_PROCESSING = "procedure-processing";
export const PAGE_CUSTOMER_COUPON = "customer-coupon";
export const PAGE_ADJUSTMENT_OPTIONS = "adjustment-options";

export const COMPONENT_PRODUCT_GRID_WITH_CATEGORY = "product_grid_with_category";
export const COMPONENT_SERVICE_TYPE_OPTION = "service_type_option";

export const CONTENT_MODEL_EMAIL = "email";
export const CONTENT_MODEL_WHATSAPP = "whatsapp";
export const CONTENT_MODEL_PDF = "pdf";
export const CONTENT_MODEL_SPREADSHEET = "spreadsheet";
export const CONTENT_MODEL_RECEIPT = "receipt";
export const CONTENT_MODEL_LABEL = "label";
export const CONTENT_MODEL_IMAGE = "image";
export const CONTENT_MODEL_WEBSITE = "website";
export const CONTENT_MODEL_BANNER = "banner";
export const CONTENT_MODEL_POPUP = "popup";
export const CONTENT_MODEL_PUSH = "push";
export const CONTENT_MODEL_PAGE = "page";
export const CONTENT_MODEL_APP = "app";
export const CONTENT_MODEL_PRODUCT = "product";
export const CONTENT_MODEL_SERVICE_OPTION = "service_option";

export const STORAGE_RESOURCES_VERSION_DEFAULT = "default";

export const STORAGE_FOLDER_ORDER = "o";
export const STORAGE_FOLDER_CHAT = "c";
export const STORAGE_FOLDER_PERMANENT = "p";
export const STORAGE_FOLDER_SIGNATURE = "s";

export const STORAGE_ID_STANDARD = "s";
export const STORAGE_ID_PRO = "p";
export const STORAGE_ID_ENTERPRISE = "e";
