"use client";

import { createContext, useContext, useMemo, useState } from "react";
import dayjs from "dayjs";

const ClientContext = createContext();

export function ClientProvider({
  children,
  platform,
  client,
  host,
  version,
  theme,
  lang,
  draftMode,
  offlineEnabled,
  onUpdateLocale,
  hasNetwork,
  urlScheme,
  onOTAUpdate,
  onOTACheck,
  otaNewVersion,
  draftPage,
  setDraftPage,
}) {
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [installId, setInstallId] = useState();
  const [pushToken, setPushToken] = useState();
  const [location, setLocation] = useState();
  const [registerDevice, setRegisterDevice] = useState();
  const [customerScreen, setCustomerScreen] = useState();
  const [state, setState] = useState({
    launchTime: Date.now(),
  });

  const context = useMemo(() => {
    return {
      installId,
      host,
      platform,
      client,
      version,
      lang,
      theme,
      draftMode,
      location,
      registerDevice,
      date,
      state,
      customerScreen,
      hasNetwork,
      offlineEnabled,
      pushToken,
      urlScheme,
      setDate,
      setPushToken,
      setState: (updates) => {
        setState(
          typeof updates === "function" ? updates : (prevState) => (updates ? { ...prevState, ...updates } : {})
        );
      },
      setInstallId,
      setRegisterDevice,
      setCustomerScreen,
      setLocation,
      onUpdateLocale,
      onOTAUpdate,
      onOTACheck,
      otaNewVersion,
      draftPage,
      setDraftPage,
    };
  }, [
    installId,
    pushToken,
    lang,
    host,
    platform,
    client,
    version,
    theme,
    draftMode,
    location,
    registerDevice,
    customerScreen,
    date,
    state,
    hasNetwork,
    offlineEnabled,
    urlScheme,
    onOTAUpdate,
    onOTACheck,
    otaNewVersion,
    draftPage,
  ]);

  return <ClientContext.Provider value={context}>{children}</ClientContext.Provider>;
}

export const useClientInstallId = () => useContext(ClientContext).installId;
export const useClientIsDraftMode = () => useContext(ClientContext).draftMode;
export const useClientHostname = () => useContext(ClientContext).host;
export const useClientLang = () => useContext(ClientContext).lang;
export const useClientType = () => useContext(ClientContext).client;
export const useClientPlatform = () => useContext(ClientContext).platform;
export const useClientVersion = () => useContext(ClientContext).version;
export const useClientTheme = () => useContext(ClientContext).theme;
export const useClientLocation = () => useContext(ClientContext).location;
export const useClientHasNetwork = () => useContext(ClientContext).hasNetwork;
export const useClientOfflineEnabled = () => useContext(ClientContext).offlineEnabled;
export const useClientRegisterDevice = () => useContext(ClientContext).registerDevice;
export const useClientState = () => useContext(ClientContext).state;
export const useClientDate = () => useContext(ClientContext).date;
export const useClientCustomerScreen = () => useContext(ClientContext).customerScreen;
export const useClientCustomerScreenSetter = () => useContext(ClientContext).setCustomerScreen;
export const useClientStateSetter = () => useContext(ClientContext).setState;
export const useClientDateSetter = () => useContext(ClientContext).setDate;
export const useClientLocationSetter = () => useContext(ClientContext).setLocation;
export const useClientRegisterDeviceSetter = () => useContext(ClientContext).setRegisterDevice;
export const useClientLangSetter = () => useContext(ClientContext).onUpdateLocale;
export const useClientInstallIdSetter = () => useContext(ClientContext).setInstallId;
export const useClientPushToken = () => useContext(ClientContext).pushToken;
export const useClientPushTokenSetter = () => useContext(ClientContext).setPushToken;
export const useClientOTAUpdate = () => useContext(ClientContext).onOTAUpdate;
export const useClientOTACheck = () => useContext(ClientContext).onOTACheck;
export const useClientOTANewVersion = () => useContext(ClientContext).otaNewVersion;
export const useClientDraftPage = () => useContext(ClientContext).draftPage;
export const useClientDraftPageSetter = () => useContext(ClientContext).setDraftPage;
export const useClientUrlScheme = () => useContext(ClientContext).urlScheme;
// TODO: IMPROVE
export const useClientPrimaryColor = () => {
  const theme = useContext(ClientContext).theme;
  return theme?.hex || theme?.primary;
};
export const useClientSecondaryColor = () => {
  const theme = useContext(ClientContext).theme;
  return theme?.secondary;
};
export const useClientErrorColor = () => useContext(ClientContext).theme?.error;
export const useClientStripePublishableKey = () => process.env.REACT_APP_stripe_pk || process.env.NEXT_PUBLIC_stripe_pk;
export const useClientStripeTestPublishableKey = () => "pk_test_BLYpxkqZV6sayItu1Vlcii5t";
export const useClientPrinterServiceId = () =>
  process.env.REACT_APP_PRINTER_SERVICE_ID || process.env.NEXT_PUBLIC_PRINTER_SERVICE_ID;
